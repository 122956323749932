import { useParams } from 'react-router-dom';

type Parse = {
    (str: string | undefined, fallback: number): number;
    (str: string | undefined, fallback?: number): number | undefined;
};

export const parse = ((str: string | undefined, fallback?: number) => {
    if (!str) return fallback;
    const parsed = parseInt(str);
    return isNaN(parsed) ? fallback : parsed;
}) as Parse;

// Extracted from useEnhedId to avoid recursion between useEnhedId and useHistoryNavigate
export const useEnhedId = () => {
    const { id: strId } = useParams<{ id: string }>();

    // Absolute value since negative IDs only concern the list
    const id = Math.abs(parse(strId, 0));
    return id;
};
