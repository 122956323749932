import { useQueryParamsStateLists } from './use-query-param-state';

/** Create id key from base key */
const ID_KEY = (key: string) => `${key}Ids`;

/** Create set key from id key */
const SET_KEY = (key: string) => {
    key = `set${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    return key;
};

/** Create update key from id key */
const UPDATE_KEY = (key: string) => {
    key = `update${key.charAt(0).toUpperCase()}${key.slice(1)}`;
    return key;
};

const BASE_KEYS = ['enh', 'byg', 'lok', 'del'] as const;
const ID_KEYS = BASE_KEYS.map(ID_KEY);

console.log('ID_KEYS', ID_KEYS);
console.log('SET_KEYS', ID_KEYS.map(SET_KEY));
console.log('UPDATE_KEYS', ID_KEYS.map(UPDATE_KEY));

/**
 * Interface for the id values of the useCTIds hook.
 */
export interface CTIds {
    enhIds: string[];
    bygIds: string[];
    lokIds: string[];
    delIds: string[];
}

/**
 * Interface for the setter functions of the useCTIds hook.
 */
export interface CTIdsSetters {
    setEnhIds: (newParam: string[]) => void;
    setBygIds: (newParam: string[]) => void;
    setLokIds: (newParam: string[]) => void;
    setDelIds: (newParam: string[]) => void;
}

/**
 * Interface for the update functions of the useCTIds hook.
 */
export interface CTIdsUpdates {
    updateEnhIds: (updateCallback: UpdateCallback) => void;
    updateBygIds: (updateCallback: UpdateCallback) => void;
    updateLokIds: (updateCallback: UpdateCallback) => void;
    updateDelIds: (updateCallback: UpdateCallback) => void;
}
export type UpdateCallback = (currentParam: string[]) => string[];

/**
 * Hook for managing the state of the CTIds query parameters.
 */
const useCTIds = () => {
    const { params, setParam } = useQueryParamsStateLists(ID_KEYS);

    const paramsObject = ID_KEYS.reduce<CTIds>((acc, key) => {
        acc[key as keyof CTIds] = params[key];
        return acc;
    }, {} as CTIds);

    const settersObject = ID_KEYS.reduce<CTIdsSetters>((acc, key) => {
        acc[SET_KEY(key) as keyof CTIdsSetters] = (newParam: string[]) => setParam(key, newParam);
        return acc;
    }, {} as CTIdsSetters);

    const updatesObject = ID_KEYS.reduce<CTIdsUpdates>((acc, key) => {
        acc[UPDATE_KEY(key) as keyof CTIdsUpdates] = (updateCallback) => setParam(key, updateCallback(params[key]));
        return acc;
    }, {} as CTIdsUpdates);

    return { ...paramsObject, ...settersObject, ...updatesObject };
};

export default useCTIds;
