import useHistoryNavigate from './extended-hooks/use-history-navigate';
import { useParams } from 'react-router-dom';

const useParam = (param: string, segment: number) => {
    const { navigate } = useHistoryNavigate();
    const { [param]: value } = useParams();

    const setValue = (newValue: string) => {
        const l = window.location;
        const path = l.pathname.split('/');
        path[segment] = newValue;
        navigate(path.join('/') + l.hash + l.search);
    };

    return [value, setValue] as const;
};

export default useParam;
