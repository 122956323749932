import Lookup from 'devextreme-react/lookup';
import { ValueChangedEvent } from 'devextreme/ui/lookup';
import useParam from '../../../shared/hooks/use-param';

interface Context {
    id: string;
    value: string;
}

const dataSource: Context[] = [
    { id: 'Context', value: 'General Context' },
    { id: 'context1', value: 'First Context' },
    { id: 'context2', value: 'Second Context' },
    { id: 'context3', value: 'Third Context' },
];

const ContextPicker = () => {
    const [context, setContext] = useParam('overviewContextName', 3);
    const selectedItem = dataSource.find((item) => item.id === context);
    if (!selectedItem) throw new Error('Kontekst blev ikke fundet!');

    const onValueChanged = (e: ValueChangedEvent) => {
        setContext(e.value.id);
    };

    return (
        <Lookup
            dataSource={dataSource}
            displayExpr='value'
            className='!rounded-none'
            value={selectedItem}
            showCancelButton={false}
            onValueChanged={onValueChanged}
        />
    );
};

export default ContextPicker;
