import { Outlet, RouteObject, useParams } from 'react-router-dom';
import { ReactNode, Suspense, lazy, useEffect } from 'react';

import BurgerMenu from './components/layout/burger/burger-menu';
import { DashboardRouterObjects } from './screens/dashboard/dashboard.router';
import { DriftRouterObjects } from './screens/drift/drift.router';
import { ExtendedEnhedList } from './components/data-getters/extended-enhed-list';
import { IUserState } from './shared/hooks/redux-use-user';
import LoadingScreen from './screens/loading';
import OverviewRouteObjects from './screens/overview/overview.router';
import { UserPermissions } from './components/restricted/interfaces';
import { fromRestrictedRouteObjects } from './components/restricted/routing';
import { useEnhedId } from './shared/hooks/use-enhed-id';
import useEnhedWhitelist from './shared/hooks/use-enhed-whitelist';
import { useGetEnhedQuery } from './shared/hooks/use-enhed-list';
import useHistoryNavigate from './shared/hooks/extended-hooks/use-history-navigate';

// Routers
// const DriftRouter = lazy(() => import('./screens/drift/drift.router'));
const MapRouter = lazy(() => import('./screens/map/map.router'));
const RapportRouter = lazy(() => import('./screens/rapport/rapport.router'));
const TestRouter = lazy(() => import('./screens/test/test.router'));
const BygRouter = lazy(() => import('./screens/byg/byg.router').then((m) => ({ default: m.BygRouter })));
const SynRouter = lazy(() => import('./screens/byg/byg.router').then((m) => ({ default: m.SynRouter })));
const StamdataRouter = lazy(() => import('./screens/byg/byg.router').then((m) => ({ default: m.StamdataRouter })));
const ServiceRouter = lazy(() => import('./screens/service/service.router'));

// Screens
// const HomeOldScreen = lazy(() => import('./screens/home-old'));
const HomeScreen = lazy(() => import('./screens/home'));
// const HomePilotScreen = lazy(() => import('./screens/home-pilot-screen'));
const AdminScreen = lazy(() => import('./screens/admin-screen'));
const SettingsScreen = lazy(() => import('./screens/settings/settings-screen'));
const SignInScreen = lazy(() => import('./screens/sign-in'));
const ResetPasswordScreen = lazy(() => import('./screens/reset-password'));
const ForgotPasswordScreen = lazy(() => import('./screens/forgot-password'));
const TimelineScreen = lazy(() => import('./screens/timeline-screen'));
const DataExtractionScreen = lazy(() => import('./screens/data-extraction/data-extraction-screen'));
const ForsyningForbrugScreen = lazy(() => import('./screens/forsyning/forsyning-forbrug-screen'));
const ForsyningStamdataScreen = lazy(() => import('./screens/forsyning/forsyning-stamdata-screen'));
const ForsyningAdminScreen = lazy(() => import('./screens/forsyning/forsyning-admin-screen'));
// Http codes
const Http404 = lazy(() => import('./screens/http/404'));

export const OuterRouterObjects: RouteObject[] = fromRestrictedRouteObjects([
    {
        index: true,
        element: <HomeScreen />,
    },
    {
        path: 'Map/*',
        element: <MapRouter />,
        permissions: UserPermissions.Map,
    },
    {
        path: 'Test/*',
        element: <TestRouter />,
        permissions: UserPermissions.Admin,
    },
    {
        path: 'Admin/*',
        element: <AdminScreen />,
        permissions: UserPermissions.Admin,
    },
    {
        path: 'Settings/*',
        element: <SettingsScreen />,
        permissions: UserPermissions.Admin,
    },
    {
        path: 'Drift/*',
        // element: <DriftRouter />,
        permissions: UserPermissions.Drift,
        children: DriftRouterObjects,
    },
    {
        path: 'Team/*',
        // element: <DriftRouter />,
        permissions: UserPermissions.Team,
        children: DriftRouterObjects,
    },
    {
        path: 'Rapport/*',
        element: <RapportRouter />,
        permissions: UserPermissions.Rapport,
    },
    {
        path: 'Bygningsdelskort/*',
        element: <BygRouter />,
        permissions: UserPermissions.Bygningsdelskort,
    },
    {
        path: 'Syn/*',
        element: <SynRouter />,
        permissions: UserPermissions.Syn,
    },
    {
        path: 'Stamdata/*',
        element: <StamdataRouter />,
        permissions: UserPermissions.Bygningsdelskort,
    },
    {
        path: 'Service/*',
        element: <ServiceRouter />,
        permissions: UserPermissions.Service,
    },
    {
        path: 'Timeline/*',
        element: <TimelineScreen />,
        permissions: UserPermissions.Drift_Journal,
    },
    {
        path: 'DataExtraction/*',
        element: <DataExtractionScreen />,
        permissions: UserPermissions.Data_Extraction,
    },
    {
        path: 'Forbrug/*',
        element: <ForsyningForbrugScreen />,
        permissions: UserPermissions.Forsyning,
    },
    {
        path: 'Forsyning/*',
        element: <ForsyningStamdataScreen />,
        permissions: UserPermissions.Forsyning,
    },
    {
        path: 'ForsyningAdmin/*',
        element: <ForsyningAdminScreen />,
        permissions: UserPermissions.Forsyning_Admin,
    },
    {
        path: 'Dashboard/*',
        permissions: [UserPermissions.Admin], // TODO: Create proper permissions
        children: DashboardRouterObjects,
    },
    {
        path: 'Overview/*',
        permissions: [UserPermissions.Admin], // TODO: Create proper permissions
        children: OverviewRouteObjects,
    },
    {
        path: '*',
        element: <Http404 />,
    },
]);

const AppContainer = ({ children }: { children: ReactNode }) => {
    return (
        <>
            <div id='App'>{children}</div>
        </>
    );
};

export const SignInRoutes = (_: IUserState): RouteObject[] => [
    {
        path: '*',
        element: (
            <AppContainer>
                <Outlet />
            </AppContainer>
        ),
        children: [
            { path: 'SignIn', element: <SignInScreen /> },
            { path: 'Reset', element: <ResetPasswordScreen /> },
            { path: 'Forgot', element: <ForgotPasswordScreen /> },
        ],
    },
];

export const PageContainer = () => {
    const id = useEnhedId();
    const { id: paramId } = useParams<{ id: string }>();

    const { isLoading, isSuccess } = useGetEnhedQuery('true');
    const { noHistoryNavigate } = useHistoryNavigate();

    const isValidId = paramId && !isNaN(parseInt(paramId));

    useEffect(() => {
        if (!isValidId) {
            noHistoryNavigate(`/0${window.location.pathname + window.location.search + window.location.hash}`);
        }
    }, [isValidId, noHistoryNavigate]);

    const whitelisted = useEnhedWhitelist();

    // Show list is id is valid but negative (including -0), or if id is 0 and not in whitelist
    const showList = (isValidId && paramId?.startsWith('-')) || (id === 0 && !whitelisted);

    const loading = !isSuccess && isLoading;

    return (
        <>
            <BurgerMenu>
                <AppContainer>
                    <Suspense fallback={<LoadingScreen />}>
                        {loading ? <LoadingScreen /> : showList ? <ExtendedEnhedList /> : <Outlet />}
                    </Suspense>
                </AppContainer>
            </BurgerMenu>
        </>
    );
};
