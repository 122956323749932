import Api from '../../../../../shared/networking/api';
import AutoForm from '../../../../auto-form';
import { EnhLookup } from './decision-render';
import { ItemTypes } from '../../../../auto-form/v1';
import { PropOf } from '../../../../../shared/utils/types';
import UtilsString from '../../../../../shared/utils/utils-string';
import useCentralizedSnackbar from '../../../../../shared/hooks/redux-use-centralized-snackbar';
import { useState } from 'react';

interface HelpdeskProps {
    closePopup(): void;
    onSubmitted(): void;
    onCanceled(): void;
    formData?: PropOf<typeof AutoForm, 'formData'>;
}

const HelpdeskCreateRender = ({ closePopup, onSubmitted, onCanceled, formData }: HelpdeskProps) => {
    const [enhFieldId, setEnhFieldId] = useState(() => new URLSearchParams(window.location.search).get('enhId') ?? '');
    const { enqueueSnackbar } = useCentralizedSnackbar();

    const _formData = [
        ...(formData ?? []),
        {
            name: 'enhedid',
            value: enhFieldId,
        },
    ];

    const handleSubmit = async (data: FormData) => {
        closePopup();

        const response = await Api.post('/Helpdesk', data);

        if (Api.ok(response)) {
            enqueueSnackbar(`Fejlmelding sendt!`, { variant: 'success' });
            onSubmitted();
            return;
        }

        enqueueSnackbar('Noget gik galt, prøv igen senere', { variant: 'error' });
        onCanceled();
    };

    const handleCancel = () => {
        onCanceled();
        closePopup();
    };

    if (UtilsString.IsNullOrWhitespace(enhFieldId))
        return <EnhLookup setEnhFieldId={setEnhFieldId} cancel={handleCancel} />;

    return (
        <AutoForm
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            items={[
                {
                    label: 'Helpdeks - Opret fejl',
                    items: [
                        {
                            id: 'problem',
                            label: 'Problem',
                            type: ItemTypes.String,
                        },
                        {
                            id: 'note',
                            label: 'Note',
                            type: ItemTypes.Block,
                        },
                        {
                            id: 'files',
                            label: 'Bilag',
                            type: ItemTypes.File,
                        },
                    ],
                },
            ]}
            formData={_formData}
        />
    );
};

const GetHelpdeskCreateRender = (props: HelpdeskProps) => <HelpdeskCreateRender {...props} />;

export default GetHelpdeskCreateRender;
