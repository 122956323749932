import CTLayer from '../../CTLayer';
import { ControlHandler } from '../../ControlHandler';
import { ControlNames } from '../../../interfaces';
import { ICTControl } from '../../CTControl';
import ReactControl from '../react-control-parent';
import { staticImplements } from '../../../../../shared/utils/decorators';
import { DragBox, Select } from 'ol/interaction';
import {
    getAppendedToQueryParamsLists,
    getQueryParams,
    getUpdatedQueryParamsLists,
} from '../../../../../shared/hooks/use-query-param-state';
import { getFeatureId } from '../../../../../screens/overview/overview.map';
import { ControlEventTarget } from '../../ControlHandlerUtils';
import { map } from '../..';
import route from '../../../../../cli/navigation/route';
import { Feature, MapBrowserEvent } from 'ol';
import { DragBoxEvent } from 'ol/interaction/DragBox';
import { getDragboxFeatures } from '../../EditingControl';
import { platformModifierKeyOnly } from 'ol/events/condition';

@staticImplements<ICTControl>()
export class OverviewControl extends ReactControl {
    public static ControlName = ControlNames.Overview;
    private layers: CTLayer[];
    private selectTool!: Select;
    private dragBox!: DragBox;

    constructor(controlHandler: ControlHandler, layers: CTLayer[]) {
        super(controlHandler, {}, {});
        this.layers = layers;
        this.addInteractions();
        this.addControlHandlerListeners();
    }

    private addInteractions() {
        // TODO: remove hardcoded string
        const KEY = 'enhIds';

        const updateIds = (ids: string[], shiftKey: boolean) => {
            if (ids.length === 0) return;

            // Add to url
            let updatedParams = getQueryParams();
            if (shiftKey) ids.forEach((id) => (updatedParams = getAppendedToQueryParamsLists(KEY, id, updatedParams)));
            else {
                updatedParams = getUpdatedQueryParamsLists(KEY, ids);
            }

            // Create new parameters
            const url = new URL(window.location.href);
            updatedParams.forEach((value, key) => {
                url.searchParams.set(key, value);
            });

            const to = url.pathname + url.hash + url.search;
            route.changeRoute(to);
        };
        // I couldn't figure out what the actual type was
        const onMapClick = (e: MapBrowserEvent<any>) => {
            // Find ids in clicked pixel
            const ids: string[] = [];
            const idGetter = getFeatureId('enhId');
            map?.forEachFeatureAtPixel(e.pixel, (f) => {
                ids.push(idGetter(f as Feature));
            });

            updateIds(ids, e.originalEvent.shiftKey);
        };

        this.dragBox = new DragBox({
            condition: platformModifierKeyOnly,
        });
        const onDragSelect = () => {
            const ids: string[] = [];
            const idGetter = getFeatureId('enhId');
            this.layers.forEach((l) => getDragboxFeatures(this.dragBox, l).forEach((f) => ids.push(idGetter(f))));

            updateIds(ids, true);
        };
        this.dragBox.on('boxend', onDragSelect);
        map?.addInteraction(this.dragBox);

        map?.on('click', onMapClick);
    }

    private addControlHandlerListeners() {
        this.controlHandler.addCustomEventListener('change', (e) => {
            switch (e.eventTarget) {
                case ControlEventTarget.ActiveILayer:
                    this.removeInteractions();
                    this.addInteractions();
                    break;
            }
        });
    }

    private removeInteractions() {
        if (this.selectTool) map!.removeInteraction(this.selectTool);
        // if (this.dragBox)
        //    this.map.removeInteraction(this.dragBox);
    }
}
