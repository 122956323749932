import { useSearchParams } from 'react-router-dom';

export const useQueryParamsState = (key: string) => {
    const [params, setParams] = useSearchParams();

    console.log('params', params);
    const setParam = (value: string) => {
        console.log('changing param', key, 'from', params.get(key), 'to', value, 'in', params);
        setParams({ ...params, [key]: value });
    };

    return [params.get(key), setParam] as const;
};

const DELIMITER = ',';

export const getQueryParams = () => new URL(window.location.href).searchParams;

export const getQueryParamsList = (key: string, params = getQueryParams()) => {
    return params.get(key)?.split(DELIMITER) ?? [];
};

export const getQueryParamsLists = (keys: string[], params = getQueryParams()) => {
    const lists = keys.reduce(
        (acc, key) => {
            acc[key] = getQueryParamsList(key, params);
            return acc;
        },
        {} as Record<string, string[]>
    );

    return lists;
};

export const getUpdatedQueryParamsLists = (
    key: string,
    value: string[],
    params = getQueryParams()
): URLSearchParams => {
    console.log('Updating param', key, 'to', value);
    if (value.length === 0) {
        if (!params.has(key)) return params;

        const newParams = { ...params };
        delete newParams[key as keyof typeof newParams];
        return newParams;
    }

    console.log('Old params:', params);
    const newParams = new URLSearchParams({ ...params, [key]: value.join(DELIMITER) });
    console.log('New params');
    return newParams;
};

export const getAppendedToQueryParamsLists = (
    key: string,
    value: string,
    params = getQueryParams()
): URLSearchParams => {
    return getUpdatedQueryParamsLists(key, [...getQueryParamsList(key, params), value], params);
};

export const getRemovedFromQueryParamsLists = (key: string, value: string, params = getQueryParams()) => {
    return getUpdatedQueryParamsLists(
        key,
        getQueryParamsList(key, params).filter((item) => item !== value),
        params
    );
};

export const useQueryParamsStateLists = (keys: string[]) => {
    const [_params, setParams] = useSearchParams();

    const params = getQueryParamsLists(keys, _params);
    const setParam = (key: string, value: string[]) => {
        setParams(getUpdatedQueryParamsLists(key, value, _params));
    };

    const append = (key: string, value: string) => {
        setParam(key, [...params[key], value]);
    };

    const remove = (key: string, value: string) => {
        setParam(
            key,
            params[key].filter((item) => item !== value)
        );
    };

    const update = (key: string, updater: (newParam: string[]) => string[]) => {
        setParam(key, updater(params[key]));
    };

    return { params, setParam, append, remove, update };
};

export const useQueryParamsStateList = (key: string) => {
    const { params, setParam, append, remove, update } = useQueryParamsStateLists([key]);
    return {
        param: params[key],
        setParam: (value: string[]) => setParam(key, value),
        append: (value: string) => append(key, value),
        remove: (value: string) => remove(key, value),
        update: (updater: (newParam: string[]) => string[]) => update(key, updater),
    };
};
