import React, { useEffect, useMemo, useRef } from 'react';

import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { smoothScrollApp } from '../../App-util';
import styled from '@mui/styles/styled';
import { useState } from 'react';
import { useCTEffect } from '../../shared/hooks/use-ct';

export const ListItemCollapseItem = styled(ListItemButton)({
    //paddingLeft: 'min(50px, 20%)'
});
export const ListItemCollapseDivider = styled(Divider)({
    marginLeft: '36px',
});

export interface IListItemCollapseProps {
    /** Mui List items in the collapse */
    children?: React.ReactNode | React.ReactNode[];
    /** Mui Icon Element */
    icon?: React.ReactNode;
    /** <ListItemText /> primary prop */
    primary?: string | React.ReactNode;
    /** <ListItemText /> secondary prop */
    secondary?: string | React.ReactNode;
    /**
     * Scroll list when an item opens
     * @default false
     */
    useScroll?: boolean;

    /**
     * @default false
     */
    openDefault?: boolean;
    unmountOnExit?: boolean;
    delayMount?: boolean;
    noTransition?: boolean;
    divider?: boolean;
    marginLeft?: string;
    /**
     * Overwrites openDefault, and uses localStorage to store the state
     */
    localStorageKey?: string;
}

const ListItemCollapse = ({
    children,
    icon,
    primary,
    secondary,
    useScroll,
    openDefault = false,
    unmountOnExit = true,
    delayMount = false,
    noTransition = false,
    divider = true,
    marginLeft,
    localStorageKey,
}: IListItemCollapseProps) => {
    const [open, setOpen] = useState(openDefault);
    const [height, setHeight] = useState(160);
    const [shouldUnmount, setShouldUnmount] = useState(unmountOnExit || delayMount);

    useCTEffect(() => {
        if (localStorageKey) {
            if (localStorage.getItem(localStorageKey) === 'true') setOpen(true);
            if (localStorage.getItem(localStorageKey) === 'false') setOpen(false);
        }
    }, []);

    const onClick = () => {
        setOpen((isOpen) => !isOpen);
        if (localStorageKey) localStorage.setItem(localStorageKey, (!open).toString());
    };
    const cArray = useMemo(() => React.Children.toArray(children), [children]);

    // const refDiv = useRef<HTMLDivElement>(null);
    const ref = useRef<HTMLUListElement>(null);

    const dScroll = (y: number) => setTimeout(() => smoothScrollApp(0, y), 150);

    useEffect(() => {
        if (ref.current != null && ref.current.offsetHeight > height) setHeight(ref.current!.offsetHeight);
    }, [open, height]);

    useEffect(() => {
        if (useScroll && open) dScroll(height);
        // else
        //     dScroll(-height);
    }, [useScroll, open, height]);

    return (
        <>
            <ListItemButton
                onClick={onClick}
                className={open ? 'list-item-collapse-open' : 'list-item-collapse-closed'}
            >
                {icon && <ListItemIcon>{icon}</ListItemIcon>}
                <ListItemText primary={primary} secondary={secondary} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse
                ref={ref}
                in={open}
                timeout='auto'
                unmountOnExit={shouldUnmount}
                onClick={() => setShouldUnmount(unmountOnExit)}
                sx={{
                    transition: noTransition ? 'none' : undefined,
                    marginLeft: marginLeft ?? undefined,
                }}
            >
                <List ref={ref} disablePadding>
                    {cArray.map((child, cIndex) => (
                        <React.Fragment key={`lic-c-i-${cIndex}`}>
                            {child}
                            {/* Divider if this isn't the last child */}
                            {cIndex !== cArray.length - 1 && divider && <ListItemCollapseDivider />}
                        </React.Fragment>
                    ))}
                </List>
            </Collapse>
        </>
    );
};

export default ListItemCollapse;
